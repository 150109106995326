import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import SocialProfile from '../../../components/SocialProfile/SocialProfile'
import { WorkshopTile } from './components/WorkshopTile'
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin
} from 'react-icons/io'
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles
} from './style'

import HomeOffice from './workshop-full.jpeg'

const SocialLinks = [
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/iamtylerwclark',
    tooltip: 'Twitter'
  }
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  // const Data = useStaticQuery(graphql`
  //   query {
  //     avatar: file(absolutePath: { regex: "/workshop.jpeg/" }) {
  //       childImageSharp {
  //         fluid(quality: 90) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     site {
  //       siteMetadata {
  //         author
  //         about
  //       }
  //     }
  //   }
  // `)

  const workshopEvents = [
    {
      title: 'Deep JavaScript Fundamentals',
      description: `So you’ve got a good understanding of JavaScript syntax. You probably have a high-level picture of standard JavaScript concepts. Maybe you’re using a framework like React, Vue, or Angular and you know how to make things that “just work”. In this workshop, I shined a light into “black-box” concepts like prototypes, the this keyword, scope, coercion, and what primitive types are.`,
      link: 'https://ti.to/magnoliajs/magnoliajs2020',
      date: 'April 14th, 2020',
      location: 'Jackson, Mississippi'
    },
    {
      title: 'Advanced SQL',
      description: `Relational databases are a tried and true cornerstone of many an application. In this workshop, I go over many "beyond the basic" features within SQL. Some of these include bulk inserts, creating custom types, profiling queries, and transactions.`,
      link:
        'https://ti.to/egghead-live-online-events/advanced-sql-with-tyler-clark-2020-02-13',
      date: 'February 13th, 2020',
      location: 'Remote'
    },
    {
      title: 'Advanced JavaScript Foundations',
      description: `So you’ve got a good understanding of JavaScript syntax. You probably have a high-level picture of standard JavaScript concepts. Maybe you’re using a framework like React, Vue, or Angular and you know how to make things that “just work”. In this workshop, I shined a light into “black-box” concepts like prototypes, the this keyword, scope, coercion, and what primitive types are.`,
      link:
        'https://ti.to/egghead-live-online-events/advanced-javascript-foundations-with-tyler-clark-2019-10-24',
      date: 'October 24th, 2019',
      location: 'Remote'
    }
  ]

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Workshops</h2>
        <p>
          Every now and then I do live paid and free workshops on a wide range
          of topics.
        </p>
      </AboutPageTitle>
      <AboutImage>
        {/* <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" /> */}
        <img src={HomeOffice} alt="home office desk" />
      </AboutImage>
      {workshopEvents.map(({ title, description, link, date, location }, i) => (
        <WorkshopTile
          key={i}
          title={title}
          description={description}
          link={link}
          date={date}
          location={location}
        />
      ))}
      <AboutDetails>
        <h2>Wanna join the party?</h2>
        <p>
          Check out my newsletter or follow me on twitter to stay up to date on
          when I will be going live.
        </p>
        <p>
          The workshops range from 2-5 hours and depending on the topic and it's
          timeframe, they range from free (promos, giveaways) to $300.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
