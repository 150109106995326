import * as React from 'react'

import {
  WorkshopTileH3,
  WorkshopImg,
  WorkshopIconText,
  WrapperTileA,
  WorkshopTileWrapper
} from './style.tsx'
import { ComingSoon } from '../../../../components/ComingSoon'
import Calendar from './icons/calendar.svg'
import Location from './icons/location.svg'
import Mouse from './icons/mouse.svg'

type WorkshopTileProps = {
  title: string
  description: string
  link: string
  date: string
  location: string
}

export const WorkshopTile: React.FunctionComponent<WorkshopTileProps> = props => {
  const talkSeconds = new Date(
    props.date
      .replace('th', '')
      .replace(',', '')
      .split(' ')
      .join('-')
  ).getTime()

  return (
    <WorkshopTileWrapper>
      {Date.now() < talkSeconds ? <ComingSoon /> : null}
      <WorkshopTileH3>{props.title}</WorkshopTileH3>
      <p>{props.description}</p>
      <div style={{ display: 'flex' }}>
        <WrapperTileA href={props.link} target="_blank">
          <WorkshopImg src={Mouse} alt="icon" />
          Tickets
        </WrapperTileA>
        <WorkshopIconText>
          <WorkshopImg src={Calendar} alt="icon" />
          {props.date}
        </WorkshopIconText>
        <WorkshopIconText>
          <WorkshopImg src={Location} alt="icon" />
          {props.location}
        </WorkshopIconText>
      </div>
      <hr style={{ margin: '20px 0' }} />
    </WorkshopTileWrapper>
  )
}
