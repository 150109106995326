import styled from 'styled-components'

export const WorkshopTileH3 = styled.h3``

export const WorkshopImg = styled.img`
  height: 30px;
`
export const WorkshopIconText = styled.div`
  display: flex;
  align-items: center;
  margin-right: 35px;
  img {
    margin-right: 5px;
  }
`
export const WrapperTileA = styled.a`
  display: flex;
  align-items: center;
  color: #000;
  margin-right: 35px;
`

export const WorkshopTileWrapper = styled.div`
  margin: 35px 0;
  position: relative;
`
