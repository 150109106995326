import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Workshop from '../containers/Workshop'

type AboutPageProps = {}

const AboutPage: React.FunctionComponent<AboutPageProps> = props => {
  return (
    <Layout>
      <SEO
        title="Workshops"
        description="I do live remote and in person workshops on topics ranging from JavaScript and SQL"
      />

      <Workshop />
    </Layout>
  )
}

export default AboutPage
